import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setupRUMClient } from './analytics/cwRumMetrics';

require('react-web-vector-icons/fonts');

const App = React.lazy(() => import('./AppWrapper'));

async function bootstrapApp() {
  await setupRUMClient();
  ReactDOM.render(
    <div className="main-container">
      <Suspense fallback={<div />}>
        <App />
      </Suspense>
    </div>,
    document.getElementById('root')
  );
}

bootstrapApp();
