/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */

import { AwsRum } from 'aws-rum-web';

let rumClient;

export async function setupRUMClient() {
  try {
    if (!window.__config.enable_CW_Rum) {
      return false;
    }
    rumClient = await initializeRUM();
  } catch (e) {
    console.log('Rum Setup failed', e);
  }
  return null;
}

export function setUserAttributes(username, email) {
  rumClient.addSessionAttributes({
    username,
    email,
  });
}

async function initializeRUM() {
  const rumConfigParameters = window.__config.cloudwatchRUM;

  const APPLICATION_ID = rumConfigParameters.monitorID;
  const APPLICATION_VERSION = rumConfigParameters.application_version;
  const APPLICATION_REGION = rumConfigParameters.application_region;

  const CONFIG = {
    sessionSampleRate: 1,
    guestRoleArn: rumConfigParameters.guestRoleArn,
    identityPoolId: rumConfigParameters.identityPoolId,
    endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
    telemetries: ['errors', 'performance', 'http'],
    allowCookies: true,
    enableXRay: false,
  };

  return new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, CONFIG);
}
// eslint-disable-next-line consistent-return
export function logRumEvent(type, payload) {
  if (!window.__config.enable_CW_Rum) {
    return false;
  }
  rumClient?.recordEvent(type, payload);
}

export default { setupRUMClient, logRumEvent };
